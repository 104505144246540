/* LOADER ANIMATION */
@-webkit-keyframes lds-grid {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@-moz-keyframes lds-grid {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@-o-keyframes lds-grid {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@keyframes lds-grid {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* AT REGISTER SIDEBAR - FILL STEPPER CIRCLE */
@-webkit-keyframes stepper-fill-circle {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        width: 100%;
    }

    100% {
        height: 100%;
    }
}
@-webkit-keyframes stepper-fill-circle-horizontal {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        height: 100%;
    }

    100% {
        width: 100%;
    }
}

@-moz-keyframes stepper-fill-circle {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        width: 100%;
    }

    100% {
        height: 100%;
    }
}
@-moz-keyframes stepper-fill-circle-horizontal {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        height: 100%;
    }

    100% {
        width: 100%;
    }
}

@-o-keyframes stepper-fill-circle {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        width: 100%;
    }

    100% {
        height: 100%;
    }
}
@-o-keyframes stepper-fill-circle-horizontal {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        height: 100%;
    }

    100% {
        width: 100%;
    }
}

@keyframes stepper-fill-circle {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        width: 100%;
    }

    100% {
        height: 100%;
    }
}
@keyframes stepper-fill-circle-horizontal {
    0% {
        width: 0;
        height: 0;
    }

    25% {
        height: 100%;
    }

    100% {
        width: 100%;
    }
}

/* AT REGISTER SIDEBAR - UN-FILL STEPPER CIRCLE */
@-webkit-keyframes stepper-fill-circle-reverse {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        height: 0;
    }

    100% {
        width: 0;
    }
}
@-webkit-keyframes stepper-fill-circle-reverse-horizontal {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        width: 0;
    }

    100% {
        height: 0;
    }
}

@-moz-keyframes stepper-fill-circle-reverse {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        height: 0;
    }

    100% {
        width: 0;
    }
}
@-moz-keyframes stepper-fill-circle-reverse-horizontal {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        width: 0;
    }

    100% {
        height: 0;
    }
}

@-o-keyframes stepper-fill-circle-reverse {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        height: 0;
    }

    100% {
        width: 0;
    }
}
@-o-keyframes stepper-fill-circle-reverse-horizontal {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        width: 0;
    }

    100% {
        height: 0;
    }
}

@keyframes stepper-fill-circle-reverse {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        height: 0;
    }

    100% {
        width: 0;
    }
}
@keyframes stepper-fill-circle-reverse-horizontal {
    0% {
        width: 100%;
        height: 100%;
    }

    75% {
        width: 0;
    }

    100% {
        height: 0;
    }
}

@-webkit-keyframes stepper-completed-connector-horizontal{
    0%{
        flex: 1 0 0;
    }
    100%{
        flex: 0 0 0;
    }

}
@-moz-keyframes stepper-completed-connector-horizontal{
    0%{
        flex: 1 0 0;
    }
    100%{
        flex: 0 0 0;
    }

}
@-o-keyframes stepper-completed-connector-horizontal{
    0%{
        flex: 1 0 0;
    }
    100%{
        flex: 0 0 0;
    }

}
@keyframes stepper-completed-connector-horizontal{
    0%{
        flex: 1 0 0;
    }
    100%{
        flex: 0 0 0;
    }

}

@-webkit-keyframes stepper-completed-step-horizontal {
    0%{
        position: static;
        transform: scale(1);
    }
    50%{
        position: absolute;
        transform: scale(1);
    }
    70%{
        position: absolute;
        transform: scale(0);
    }
    100%{
        position: absolute;
        transform: scale(1.5);
    }
}
@-moz-keyframes stepper-completed-step-horizontal {
    0%{
        position: static;
        transform: scale(1);
    }
    50%{
        position: absolute;
        transform: scale(1);
    }
    70%{
        position: absolute;
        transform: scale(0);
    }
    100%{
        position: absolute;
        transform: scale(1.5);
    }
}
@-o-keyframes stepper-completed-step-horizontal {
    0%{
        position: static;
        transform: scale(1);
    }
    50%{
        position: absolute;
        transform: scale(1);
    }
    70%{
        position: absolute;
        transform: scale(0);
    }
    100%{
        position: absolute;
        transform: scale(1.5);
    }
}
@keyframes stepper-completed-step-horizontal {
    0%{
        position: static;
        transform: scale(1);
    }
    50%{
        position: absolute;
        transform: scale(1);
    }
    70%{
        position: absolute;
        transform: scale(0);
    }
    100%{
        position: absolute;
        transform: scale(1.5);
    }
}

@-webkit-keyframes stepper-completed-svg-horizontal {
    0%{
        color: #325135;
    }
    100%{
        color: #FFFFFF;
        background-color: #325135;
        stroke-width: 2px;
    }
}
@-moz-keyframes stepper-completed-svg-horizontal {
    0%{
        color: #325135;
    }
    100%{
        color: #FFFFFF;
        background-color: #325135;
        stroke-width: 2px;
    }
}
@-o-keyframes stepper-completed-svg-horizontal {
    0%{
        color: #325135;
    }
    100%{
        color: #FFFFFF;
        background-color: #325135;
        stroke-width: 2px;
    }
}
@keyframes stepper-completed-svg-horizontal {
    0%{
        color: #325135;
    }
    100%{
        color: #FFFFFF;
        background-color: #325135;
        stroke-width: 2px;
    }
}

/* AT REGISTER SIDEBAR - STTEPPER CHECK ICON */
@-webkit-keyframes stepper-completed-ani {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@-moz-keyframes stepper-completed-ani {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@-o-keyframes stepper-completed-ani {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes stepper-completed-ani {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}