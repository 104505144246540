@font-face {
    font-family: 'Eudoxussans,sans-serif';
    src: url('../fonts/EudoxusSans-Regular.eot');
    src: url('../fonts/EudoxusSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EudoxusSans-Regular.woff2') format('woff2'),
        url('../fonts/EudoxusSans-Regular.woff') format('woff'),
        url('../fonts/EudoxusSans-Regular.ttf') format('truetype'),
        url('../fonts/EudoxusSans-Regular.svg#EudoxusSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussans,sans-serif';
    src: url('../fonts/EudoxusSans-Medium.eot');
    src: url('../fonts/EudoxusSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EudoxusSans-Medium.woff2') format('woff2'),
        url('../fonts/EudoxusSans-Medium.woff') format('woff'),
        url('../fonts/EudoxusSans-Medium.ttf') format('truetype'),
        url('../fonts/EudoxusSans-Medium.svg#EudoxusSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussans,sans-serif';
    src: url('../fonts/EudoxusSans-ExtraBold.eot');
    src: url('../fonts/EudoxusSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EudoxusSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/EudoxusSans-ExtraBold.woff') format('woff'),
        url('../fonts/EudoxusSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/EudoxusSans-ExtraBold.svg#EudoxusSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

