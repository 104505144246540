.addcontractorpricingplan-sidebar {
  position: absolute;
  top: 10%;
  left: 5%;
}
.SubheaderLink {
  font-family: "Figtree, sans-serif" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #2e4d31 !important;
}

.cardGrid {
  text-align: -webkit-center !important;
}

.cardBox {
  width: 250px !important;
  padding: 8px !important;
  background: #39603d !important;
  border-radius: 5px !important;
}

.cardHeader {
  font-family: "Figtree, sans-serif" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #ffffff !important;
  padding-bottom: 30px !important;
}

.cardavtarbox {
  width: 50px !important;
  height: 50px !important;
  background: rgb(77, 112, 80) !important;
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: 5px !important;
  z-index: 999 !important;
  position: absolute !important;
  top: 37% !important;
  left: 64% !important;
}

.avtarIcon {
  color: #ffffff !important;
  height: 35px !important;
  width: 35px !important;
  padding-top: 5px !important;
}

.cardContentBoxGrid {
  padding: 0% !important;
}

.cardContentBox {
  background: #ffffff !important;
  border-radius: 5px !important;
  z-index: 0 !important;
  padding: 10px !important;
}

.cardContentBoxHeader {
  font-family: "Figtree, sans-serif" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  line-height: 23px !important;
  color: #000000 !important;
}

.cardContentpricing {
  place-content: center !important;
}

.cardPrice {
  font-family: "Figtree, sans-serif" !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 41px !important;
  color: #39603d !important;
}

.cardPriceFloat {
  font-family: "Figtree, sans-serif" !important;
  padding: 0px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #39603d !important;
}

.cardPricelabel {
  font-family: "Figtree, sans-serif" !important;
  padding: 0px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #39603d !important;
}

.cardList {
  font-family: "Figtree, sans-serif" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #060a06 !important;
  text-align: left !important;
}

.ListIcon {
  height: 10px !important;
  width: 10px !important;
  padding: 0px !important;
}

.divider {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
