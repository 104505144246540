@media (min-width: 900px) and (min-height: 850px) {
  .login_content_grid_item.MuiGrid2-root {
    height: 100vh;
  }
}

@media (min-width: 900px) and (max-height: 789px) {
  .sidebar_layout .drawer_body {
    padding: 0;
  }
}

@media (min-width: 900px) and (max-height: 749px) {
  .sidebar_layout.MuiGrid2-root {
    height: 100%;
  }

  .sidebar_layout .sidebar_layout_drawer {
    height: auto;
  }

  .sidebar_layout .sidebar_layout_page {
    height: auto;
    min-height: 100vh;
  }

  .sidebar_layout .MuiDrawer-docked .MuiDrawer-paper {
    position: static;
  }
}

@media (max-width: 1279px) {
  .company_info_sidebar .avatar_container .img_bx {
    margin-right: 15px;
  }
}

@media (max-width: 1024px) {

  /* LOGIN/REGISTER SIDEBAR */
  .register-stepper .MuiStepLabel-label {
    font-size: 17px;
  }

  /* SIDEBAR */
  .sidebar_layout .drawer_header {
    padding: 20px;
  }

  .sidebar_layout .drawer_body p.list_item_heading {
    padding: 15px 20px;
  }

  .sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root {
    padding: 10px 20px;
  }

  .sidebar_layout .drawer_footer {
    padding: 15px;
  }

  .sidebar_layout .drawer_footer .MuiAvatar-root {
    margin-right: 10px;
  }

  /* TEAM */
  .employe_profile_container_item.MuiGrid2-root {
    width: 100%;
  }
}

@media (max-width: 899px) {

  /* LOGIN */
  .login_page_img {
    text-align: center;
  }

  .login_page .login_cont h3 {
    font-size: 27px;
    margin-bottom: 15px;
  }

  .appbar_menu .MuiButton-root {
    font-size: 16px;
  }

  /* REGISTER */
  .login_grid.MuiGrid2-container {
    height: 100%;
  }

  .login-sidebar {
    width: 100%;
    height: auto;
  }

  .login_sidebar_container {
    padding: 20px 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .login-sidebar .logo-img {
    margin: 0;
  }

  .login_sidebar_content {
    display: none;
  }

  .login_grid.show_sidebar_content .login_sidebar_content {
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  .login_grid.show_sidebar_content .login-sidebar .MuiGrid2-root.register-sidebar-addteam {
    margin: 0;
  }

  .login_grid.show_sidebar_content .login-sidebar .MuiGrid2-root.register-sidebar-addteam .avatar-item {
    padding-left: 0;
  }

  .login_grid.show_sidebar_content .login-sidebar .sidebar_checkbox_list,
  .login_grid.show_sidebar_content .login-sidebar .terms_condition {
    display: none;
  }

  .login_grid.show_sidebar_content .login-sidebar .register-stepper {
    display: none;
  }

  .login_sidebar_logout {
    margin-top: 0;
  }

  .login_content_grid_item.MuiGrid2-root {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 70px);
    padding-left: 30px;
    padding-right: 30px;
  }

  .auth_top_header.MuiGrid-container,
  .auth_top_header.MuiGrid2-container {
    padding: 30px 0 50px;
  }

  .sidebar_layout_page .auth_top_header.MuiGrid2-container {
    padding: 0 0 30px;
  }

  .register_form_card {
    padding: 15px;
  }

  /* SIDEBAR */
  .sidebar_layout.MuiGrid2-root {
    /* height: 100%; */
    padding-top: 76px;
  }

  .sidebar_layout .sidebar_layout_drawer {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }

  .sidebar_layout .MuiDrawer-docked .MuiDrawer-paper {
    position: static;
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_header .MuiGrid2-container {
    justify-content: space-between;
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_header_logo {
    display: block;
  }

  .sidebar_layout .drawer_body {
    position: fixed;
    left: 0;
    background-image: linear-gradient(180deg,
        var(--themedark-color-hexa) 0%,
        var(--themelight-color-hexa) 100%);
    width: 100%;
    max-width: 280px;
    height: 100%;
    z-index: 1;
    transform: translate(-120%, 0);
    transition: all 0.4s ease;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
      0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body {
    transform: none;
    padding: 10px 0 0;
  }

  .sidebar_layout .drawer_body .MuiListItem-root {
    margin-bottom: 0;
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body .MuiListItem-root .MuiListItemText-root {
    display: block;
  }

  .sidebar_layout .drawer_footer {
    display: none;
  }

  .sidebar_layout .drawer_header,
  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .MuiDrawer-docked .drawer_header {
    padding: 15px 30px;
  }

  .sidebar_layout .drawer_header .MuiIconButton-root .icon_hamburger_sidebar {
    display: none;
  }

  .sidebar_layout .drawer_header .MuiIconButton-root .icon_hamburger_menu {
    display: block;
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle {
    max-width: 100%;
  }

  .MuiButton-root.drawer_body_close {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }

  .MuiButton-root.drawer_body_close:hover,
  .MuiButton-root.drawer_body_close:focus,
  .MuiButton-root.drawer_body_close:active {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .MuiButton-root.drawer_body_close {
    display: block;
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body p.list_item_heading {
    display: block;
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body .MuiListItem-root .MuiButtonBase-root {
    padding: 10px 20px;
  }

  .sidebar_layout .sidebar_layout_page {
    padding: 30px 30px;
    min-height: calc(100vh - 76px);
  }

  .sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle+.sidebar_layout_page {
    overflow: hidden;
    height: calc(100vh - 76px);
    padding-right: 35px;
  }

  .need_attention_grid_cont {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .need_attention_grid_img {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .need_attention_grid_img img {
    width: 75%;
    max-width: 180px;
  }

  .need_attention_img_skelton {
    margin: auto;
  }

  .updated_bx_close_bttn.MuiIconButton-root {
    top: 10px;
  }

  .add_hire_modal .modal_body {
    padding: 30px 20px;
  }

  .modal_employe_type_bx.MuiButton-root {
    padding: 20px 15px;
  }

  .add_hire_modal h6 {
    margin-bottom: 5px;
  }

  .employe_profile_bx.responsive_collapse .employe_profile_head.MuiGrid2-root {
    padding: 10px 20px;
    background-color: rgba(46, 77, 49, 0.4);
  }

  .employe_profile_bx.responsive_collapse .employe_profile_head.MuiGrid2-root .material-icons {
    background-color: transparent;
  }

  .employe_profile_bx.responsive_collapse .employe_profile_head.MuiGrid2-root .material-icons {
    margin-right: 6px;
  }

  .employe_profile_body {
    padding: 10px 30px 30px;
  }

  .add_hire_modal.new_request_modal .modal_footer,
  .add_hire_modal.company_setting_modal .modal_footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .add_hire_modal.new_request_modal .modal_foot_bttn_container .MuiButtonBase-root,
  .add_hire_modal.company_setting_modal .modal_foot_bttn_container .MuiButtonBase-root {
    padding: 10px 15px;
    font-size: 14px;
  }

  .add_hire_modal.new_request_modal .modal_foot_bttn_container .MuiButtonBase-root:not(:last-child),
  .add_hire_modal.company_setting_modal .modal_foot_bttn_container .MuiButtonBase-root:not(:last-child) {
    margin-right: 15px;
  }

  .dash_tabs .MuiTab-root.MuiButtonBase-root:not(:last-child) {
    margin-right: 20px;
  }

  .MuiButton-root.help_bttn {
    right: 15px;
    bottom: 15px;
    font-size: 0;
    padding: 6px;
    width: 40px;
    min-width: auto;
    height: 40px;
    border-color: transparent;
  }

  .MuiButton-root.help_bttn .MuiButton-startIcon {
    margin: 0;
  }

  .empregister_logout_bttn.MuiButtonBase-root {
    left: 15px;
    bottom: 15px;
  }

  .user_header {
    padding: 15px;
  }
}

@media (max-width: 899px) and (max-height: 705px) {
  .sidebar_layout .drawer_body {
    overflow-y: auto;
  }
}

@media (max-width: 599px) {
  body .notistack-SnackbarContainer {
    top: auto;
    bottom: 14px;
  }

  .login_page_img img {
    max-width: 300px;
  }

  .register-sidebar h3{
    font-size: 22px;
  }

  .add_hire_modal .modal_body {
    max-height: 400px;
  }

  .user_header_profile .profile_cont {
    display: none;
  }
}

@media (max-width: 369px) {

  .add_hire_modal.new_request_modal .modal_footer,
  .add_hire_modal.company_setting_modal .modal_footer {
    padding: 15px;
  }

  .employe_profile_head .MuiTypography-root {
    font-size: 15px;
  }

  .data_table_filters_grid .filter_group {
    margin-right: 10px;
  }

  .data_table .MuiTablePagination-root .MuiTablePagination-selectLabel,
  .data_table .MuiTablePagination-root .MuiTablePagination-select {
    font-size: 11px;
  }

  .data_table .MuiTablePagination-root .MuiTablePagination-select.MuiInputBase-input {
    padding-right: 20px;
  }

  .data_table .MuiTablePagination-root .MuiInputBase-root {
    margin-right: 10px;
  }

  .data_table .MuiTablePagination-root .MuiTablePagination-actions {
    margin-left: 10px;
  }
}