/* @import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


/* font-family: "Figtree", sans-serif; */    /* FONTS FOR ADMIN PANEL (OLD - Not In Use) */
/* font-family: "Montserrat", sans-serif; */ /* FONTS FOR ADMIN PANEL (In Use) */
/* font-family: 'Eudoxussans,sans-serif'; */ /* FONTS FOR USERS PANEL (In Use) */


:root {
  /* COLORS */
  --theme-color-hexa: #39603d;
  --theme-color-hexa-50: #304f33;

  --themelight-color-hexa: #5d7c60;
  --themelight-color-hexa-50: #436246;

  --themedark-color-hexa: #2e4d31;
  --themedark-color-rgba: 46, 77, 49;
  --themedark-color-hexa-50: #2b422d;

  --black-color-hexa: #000000;
  --black-color-rgb: 0, 0, 0;
  --black-color-hexa-50: #060a06;

  --white-color-hexa: #ffffff;
  --white-color-hexa-50: #f4f4f4;

  --light-color-hexa: #eeeeee;
  --light-color-hexa-50: #eaeaea;

  --red-color-hexa: #ff0000;

  /* FONTS */
  /* --theme-font: "Figtree", sans-serif; */
  --theme-font: "Montserrat", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::selection {
  background-color: rgba(var(--themedark-color-rgba), 0.25);
  color: var(--theme-color-hexa);
}

img,
svg,
video {
  max-width: 100%;
}

body {
  background-color: var(--light-color-hexa-50);
  height: 100%;
  font-size: 15px;
  line-height: 1.4;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

body,
body *[class^="Mui"] {
  font-family: var(--theme-font);
}

body .MuiButtonBase-root {
  text-transform: initial;
}

body.no-scroll {
  overflow: hidden;
  padding-right: 5px;
}

a,
button,
input,
textarea,
select {
  outline: 0;
}

/* SCROLLBAR */
/* Fallback styles */
:root {
  scrollbar-width: thin;
  scrollbar-color: var(--theme-color-hexa) var(--light-color-hexa);
}

/* WebKit-based browsers */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--light-color-hexa);
}

::-webkit-scrollbar-thumb {
  background: var(--theme-color-hexa);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--themedark-color-hexa-50);
}

/* Mozilla Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--theme-color-hexa) var(--light-color-hexa);
}

*::-moz-scrollbar {
  width: 5px;
  height: 5px;
}

*::-moz-scrollbar-track {
  background: var(--light-color-hexa);
}

*::-moz-scrollbar-thumb {
  background: var(--theme-color-hexa);
  border-radius: 50px;
}

*::-moz-scrollbar-thumb:hover {
  background: var(--themedark-color-hexa-50);
}

.height-100 {
  height: 100vh;
}

.Mui-selected p {
  font-weight: 800;
}

body .notistack-Snackbar .notistack-MuiContent {
  flex-wrap: nowrap;
}

body .notistack-Snackbar .notistack-MuiContent-success {
  background-color: #edf7ed;
  color: #1e4620;
  font-weight: 600;
}

body .notistack-Snackbar .notistack-MuiContent-error {
  background-color: #ffe6e8;
  color: var(--red-color-hexa);
  font-weight: 600;
}

body .notistack-Snackbar .notistack-MuiContent-warning {
  background-color: #ffd8a5;
  color: rgb(102, 60, 0);
  font-weight: 600;
}

body .notistack-Snackbar .notistack-MuiContent-info {
  background-color: #b9ebff;
  color: #014361;
  font-weight: 600;
}

.MuiTooltip-popper {
  max-width: 250px;
}

.modalbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: "background.paper";
  background: #f8f8f8;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 24;
}

.maodalclose {
  text-align-last: right;
}

.modalDivider {
  height: 0px !important;
  border: 1px solid rgba(46, 77, 49, 0.7) !important;
  margin-bottom: 2 !important;
}

.modalLink {
  margin-left: 5px;
}

.modalcontent {
  align-items: flex-end !important;
}

.add_hire_modal {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.add_hire_modal .MuiModal-backdrop {
  background-color: rgba(40, 40, 40, 0.5);
}

.add_hire_modal .MuiDialog-paper {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}

.add_hire_modal_bx {
  background: #f8f8f8;
  border-radius: 10px;
  margin: auto;
  width: 100%;
  max-width: 610px;
  min-height: 432px;
  display: flex;
  flex-direction: column;
}

.add_hire_modal_bx:focus, 
.add_hire_modal_bx:focus-visible {
  outline: 0;
}

.add_hire_modal.converted_currencies_modal .add_hire_modal_bx {
  min-height: auto;
}

.add_hire_modal .modal_header {
  padding: 19px 30px;
  border-bottom: 2px solid rgba(46, 77, 49, 0.7);
}

.add_hire_modal .modal_header .MuiGrid-container,
.add_hire_modal .modal_header .MuiGrid2-container {
  justify-content: space-between;
  align-items: center;
}

.add_hire_modal .modal_header .MuiTypography-root {
  font-weight: 800;
  font-size: 17px;
  line-height: 1.4;
  color: var(--themedark-color-hexa);
  margin: 0;
}

.add_hire_modal .modal_header .MuiIconButton-root,
.add_hire_modal .modal_header .icon_close_bttn {
  padding: 0;
}

.add_hire_modal .modal_body {
  padding: 24px 55px 30px;
  flex: 1 0 0;
  max-height: 400px;
  overflow: auto;
}

.modal_employe_type_bx.MuiButton-root {
  width: 100%;
  background: var(--white-color-hexa);
  border-radius: 10px;
  padding: 14px 30px 20px;
  text-align: left;
  transition: all 0.4s ease;
}

.modal_employe_type_bx.MuiButton-root .MuiGrid-item:first-child {
  text-align: center;
}

.modal_employe_type_bx.MuiButton-root.Mui-selected {
  color: var(--themedark-color-hexa);
}

.modal_employe_type_bx.MuiButton-root:hover,
.modal_employe_type_bx.MuiButton-root:focus,
.modal_employe_type_bx.MuiButton-root:active {
  background-color: var(--themedark-color-hexa);
  color: var(--white-color-hexa);
}

.modal_employe_type_bx.MuiButton-root:hover h6,
.modal_employe_type_bx.MuiButton-root:hover p,
.modal_employe_type_bx.MuiButton-root:focus h6,
.modal_employe_type_bx.MuiButton-root:focus p,
.modal_employe_type_bx.MuiButton-root:active h6,
.modal_employe_type_bx.MuiButton-root:active p {
  color: var(--white-color-hexa);
}

.modal_employe_type_bx.MuiButton-root:not(:last-child) {
  margin-bottom: 16px;
}

.modal_employe_type_bx.MuiButton-root .MuiGrid-container {
  align-items: center;
  justify-content: space-between;
}

.add_hire_modal h6 {
  font-weight: 800;
  font-size: 17px;
  line-height: 1.4;
  color: var(--themedark-color-hexa);
  margin-bottom: 10px;
}

.add_hire_modal p {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa);
}

.add_hire_modal p a {
  color: var(--theme-color-hexa);
  text-decoration: none;
}

.add_hire_modal p a:hover,
.add_hire_modal p a:focus {
  text-decoration: underline;
}

.add_hire_modal .modal_body>p {
  margin-bottom: 26px;
}

.add_hire_modal .modal_footer {
  background-color: rgba(47, 78, 50, 0.1);
  padding: 24px 30px;
}

.add_hire_modal .see_guide_bttn.MuiButton-root {
  background: var(--white-color-hexa);
  border: 2px solid var(--theme-color-hexa);
  box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15);
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--theme-color-hexa);
  margin-top: 30px;
}

.add_hire_modal .see_guide_bttn.MuiButton-root:hover,
.add_hire_modal .see_guide_bttn.MuiButton-root:focus,
.add_hire_modal .see_guide_bttn.MuiButton-root:active {
  background-color: var(--theme-color-hexa);
  color: var(--white-color-hexa);
}

.modal_foot_bttn_container {
  justify-content: center;
}

.modal_foot_bttn_container .MuiButtonBase-root {
  background-color: #e4e7e4;
  border: 2px solid var(--theme-color-hexa);
  box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15);
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--theme-color-hexa);
}

.modal_foot_bttn_container .MuiButtonBase-root:hover,
.modal_foot_bttn_container .MuiButtonBase-root:focus {
  background-color: var(--theme-color-hexa);
  color: var(--white-color-hexa);
}

.modal_foot_bttn_container .MuiButtonBase-root:not(:last-child) {
  margin-right: 50px;
}

.modal_foot_bttn_container .MuiButtonBase-root.save_bttn {
  background-color: var(--theme-color-hexa);
  color: var(--white-color-hexa);
}

.modal_foot_bttn_container .MuiButtonBase-root.save_bttn:hover {
  background-color: #e4e7e4;
  color: var(--theme-color-hexa);
}

.add_hire_modal .modal_body .register_form {
  margin-top: 0;
  margin-bottom: 32px;
}

.new_request_modal_cont {
  background: #d9d9d9;
  border-radius: 10px;
  padding: 20px 17px;
  margin-bottom: 35px;
}

.new_request_modal_cont .MuiGrid-container,
.new_request_modal_cont .MuiGrid2-container {
  justify-content: space-between;
  align-items: flex-start;
}

.request-modal-employe-listitem span:not(.MuiTouchRipple-root) {
  width: 100%;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal_select_employe_dropdown {
  margin-top: 35px;
}

.converted_currencies_list.MuiGrid2-root {
  margin-bottom: 15px;
}

.converted_currencies_list .MuiTypography-root {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4;
  color: var(--black-color-hexa);
}

.converted_currencies_list .MuiTypography-root.MuiTypography-body1 {
  font-weight: 600;
}

.converted_currencies_list .MuiTypography-root.MuiTypography-body2 {
  word-break: break-all;
}

.theme_bttn.MuiButton-root {
  background-color: var(--white-color-hexa);
  border: 2px solid var(--theme-color-hexa);
  box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15);
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--theme-color-hexa);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: initial;
}

.theme_bttn.MuiButton-root.Mui-disabled {
  opacity: 0.5;
}

.theme_bttn.MuiButton-root:hover,
.theme_bttn.MuiButton-root:focus,
.theme_bttn.MuiButton-root:active {
  background-color: var(--theme-color-hexa);
  color: var(--white-color-hexa);
}

.theme_bttn.MuiButton-root.fill {
  background-color: var(--theme-color-hexa);
  color: var(--white-color-hexa);
}

.theme_bttn.MuiButton-root.fill:hover,
.theme_bttn.MuiButton-root.fill:focus,
.theme_bttn.MuiButton-root.fill:active {
  background-color: var(--white-color-hexa);
  color: var(--theme-color-hexa);
}

.theme_bttn.MuiButton-root.transparent {
  padding: 0;
  border: none;
  background-color: transparent !important;
  box-shadow: none;
}

.theme_bttn.MuiButton-root.transparent:hover,
.theme_bttn.MuiButton-root.transparent:focus,
.theme_bttn.MuiButton-root.transparent:active {
  color: var(--theme-color-hexa);
}

.theme_bttn.MuiButton-root>img,
.theme_bttn.MuiButton-root>svg {
  margin-right: 10px;
}

/* LOGIN & REGISTER */
.login_page {
  background-color: var(--white-color-hexa);
  padding-top: 64px;
}

.login_page_img {
  text-align: right;
}

.login_page_img img {
  width: 100%;
  max-width: 450px;
}

nav.nav_appbar {
  background-color: var(--white-color-hexa);
  box-shadow: none;
}

nav.nav_appbar .MuiToolbar-root {
  padding: 0;
}

.appbar_menu .MuiButton-root {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: var(--themedark-color-hexa);
  background-color: transparent;
  padding: 0;
  font-family: 'Eudoxussans,sans-serif';
}

.appbar_menu .MuiButton-root:not(:first-child) {
  margin-left: 30px;
}

.appbar_menu .MuiButton-root:hover,
.appbar_menu .MuiButton-root:focus {
  text-decoration: underline;
  background-color: transparent;
}

.appbar_drawer_toggler.MuiIconButton-root {
  margin-right: 0;
}

.appbar_drawer .MuiPaper-root {
  background: linear-gradient(180deg,
      var(--themedark-color-hexa) 0%,
      var(--themelight-color-hexa) 100%);
}

.appbar_drawer_logo.MuiGrid2-root {
  padding: 16px 24px;
}

.appbar_drawer_logo.MuiGrid2-root {
  padding: 16px 24px;
}

.appbar_drawer_menu .MuiListItem-root .MuiListItemButton-root {
  padding: 12px 24px;
  color: var(--white-color-hexa);
}

.appbar_drawer_menu .MuiListItem-root .MuiListItemButton-root:hover,
.appbar_drawer_menu .MuiListItem-root .MuiListItemButton-root:focus,
.appbar_drawer_menu .MuiListItem-root .MuiListItemButton-root:active {
  background-color: var(--white-color-hexa);
  color: var(--themelight-color-hexa-50);
}

.appbar_drawer_menu .MuiListItem-root .MuiListItemButton-root .MuiListItemText-root {
  margin: 0;
}

.appbar_drawer_menu .MuiListItem-root .MuiListItemButton-root .MuiTypography-root {
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  margin: 0;
  font-family: 'Eudoxussans,sans-serif';
}

.login_grid.MuiGrid2-container {
  position: relative;
  height: auto;
  min-height: 100vh;
  margin: 0;
}

.login_grid.MuiContainer-root {
  position: relative;
  min-height: calc(100vh - 64px);
  height: 100%;
  display: flex;
  align-items: center;
}

.login_page .login_cont {
  margin: 0;
}

.login-sidebar {
  background-image: linear-gradient(180deg,
      var(--themedark-color-hexa) 0%,
      var(--themelight-color-hexa) 100%);
  height: auto;
}

.login-sidebar *::selection {
  background-color: var(--white-color-hexa);
  color: var(--themedark-color-hexa);
}

.login_sidebar_container {
  width: 100%;
  height: auto;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
}

.login_sidebar_container::-webkit-scrollbar {
  display: none;
}

.login-sidebar .logo-img {
  z-index: 1;
  margin-bottom: 64px;
}

.login_content_grid_item.MuiGrid2-root {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding-top: 0;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.login_content_grid_item.MuiGrid2-root.add_employe_final_cont {
  height: auto;
  flex: 1 0 0;
}

.login_page .login_content_grid_item.MuiGrid2-root {
  padding-left: 0;
  padding-right: 0;
}

.login_sidebar_logout {
  margin-top: auto;
}

.login_sidebar_logout .theme_bttn.MuiButton-root.transparent {
  color: var(--white-color-hexa);
}

.login_sidebar_logout .theme_bttn.MuiButton-root.transparent:hover,
.login_sidebar_logout .theme_bttn.MuiButton-root.transparent:focus,
.login_sidebar_logout .theme_bttn.MuiButton-root.transparent:active {
  color: var(--red-color-hexa);
}

.login_page .login_content_grid_item.MuiGrid2-root {
  align-items: center;
  flex-wrap: wrap-reverse;
  height: auto;
  min-height: auto;
  overflow: hidden;
}

.login_cont {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 30px 0;
  align-self: auto;
}

.login_cont.text_left {
  text-align: left;
}

.add_employe_final_cont .login_cont {
  max-width: 520px;
}

/* .add_employe_final_cont.stopContScrolling {
  overflow: hidden;
  height: 100vh;
} */

.login_cont h3 {
  font-weight: 800;
  font-size: 27px;
  line-height: 1.4;
  color: var(--themedark-color-hexa);
}

.login_page .login_cont h3 {
  font-size: 40px;
  margin-bottom: 40px;
}

.login_cont.register_cont h3 {
  font-weight: 500;
}

.login_cont p,
.login_cont li {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  color: rgb(6, 10, 6);
  margin-bottom: 10px;
}

.login_cont p a {
  font-weight: 800;
  color: var(--themedark-color-hexa);
  text-decoration: none;
}

.login_cont p a:hover,
.login_cont p a:focus {
  text-decoration: underline;
}

.login_cont .MuiTypography-h5 {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
}

.login_cont .MuiList-root {
  padding-left: 20px;
}

.login_cont .MuiList-root .MuiListItem-root {
  display: list-item;
  list-style-type: disc;
  padding: 0;
}

.login_bttn_wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.login_bttn_wrapper .login_bttn {
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  text-transform: capitalize;
  color: var(--white-color-hexa);
  padding: 10px 20px;
  gap: 10px;
  background-color: var(--theme-color-hexa);
  box-shadow: rgba(var(--black-color-rgb), 0.15) 0px 4px 6px;
  margin-top: 10px;
}

.login_bttn_wrapper .login_bttn.Mui-disabled {
  background-color: var(--theme-color-hexa);
  color: var(--white-color-hexa);
  opacity: 0.5;
}

.login_bttn_wrapper .login_bttn:hover {
  background-color: var(--themedark-color-hexa);
  box-shadow: none;
}

.register-sidebar {
  position: absolute;
  top: 10%;
  left: 5%;
}

.register-sidebar-addteam .MuiAvatar-root {
  background: rgba(217, 217, 217, 0.3);
  border: 2px solid rgb(244, 244, 244);
}

.register-stepper {
  margin-top: 65px;
}

.register-stepper.MuiStepper-horizontal {
  margin: 35px 0 50px;
}

.register-stepper.MuiStepper-horizontal.all-steps-completed {
  justify-content: center;
  width: 100%;
  height: 50px;
  position: relative;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root {
  padding: 0;
}

.register-stepper.MuiStepper-horizontal.all-steps-completed .MuiStep-root {
  animation-name: stepper-completed-step-horizontal;
  animation-duration: 0.3s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.register-stepper .MuiStepLabel-root {
  padding: 0;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer {
  width: 50px;
  height: 50px;
  /* border: 2px solid var(--white-color-hexa); */
  border: none;
  background-color: #c5c5c5;
  border-radius: 50%;
  padding: 0;
  margin-right: 20px;
  position: relative;
  transition-delay: 0.5s;
  overflow: hidden;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer {
  margin-right: 0;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: table;
  margin: auto;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: #446247;
  border-radius: 50%;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer:after {
  background-color: #eaeaea;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: table;
  margin: auto;
  width: 0;
  height: 0;
  background-color: #ffffff;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer:before {
  background-color: #325135;
  right: auto;
  bottom: 0;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer.Mui-completed:before,
.register-stepper .MuiStep-root .MuiStepLabel-iconContainer.Mui-active:before {
  width: 100%;
  height: 100%;
  animation-name: stepper-fill-circle;
  animation-duration: 0.8s;
  animation-delay: 0.8s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer.Mui-completed:before,
.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer.Mui-active:before {
  animation-name: stepper-fill-circle-horizontal;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer.Mui-disabled:before {
  width: 0;
  height: 0;
  animation-name: stepper-fill-circle-reverse;
  animation-duration: 0.8s;
  animation-delay: 0.8s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer.Mui-disabled:before {
  animation-name: stepper-fill-circle-reverse-horizontal;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  color: transparent;
  z-index: 2;
}

.register-stepper .MuiStep-root .MuiStepLabel-root.Mui-disabled .MuiStepIcon-text {
  fill: #c5c5c5;
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-root.Mui-disabled .MuiStepIcon-text {
  fill: rgba(50, 81, 53, 0.5);
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-root .MuiStepIcon-text {
  fill: #325135;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  animation-name: stepper-completed-ani;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed {
  color: var(--white-color-hexa);
}

.register-stepper.MuiStepper-horizontal .MuiStep-root .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed {
  color: #325135;
}

.register-stepper.MuiStepper-horizontal.all-steps-completed .MuiStep-root .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed {
  animation-name: stepper-completed-svg-horizontal;
  animation-duration: 0.3s;
  animation-delay: 0.7s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.register-stepper .MuiStep-root .MuiStepLabel-iconContainer .MuiSvgIcon-root .MuiStepIcon-text {
  transition: all 0.5s ease;
  transition-delay: 0.5s;
}

.register-stepper .MuiStepLabel-label {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: #c5c5c5;
  transition: 0.3s ease;
  transition-delay: 0.5s;
}

.register-stepper .MuiStepLabel-label.Mui-active,
.register-stepper .MuiStepLabel-label.Mui-completed {
  color: var(--white-color-hexa-50);
}

.register-stepper .MuiStepConnector-root {
  margin-left: 24px;
  position: relative;
  width: 2px;
  background-color: #bdbdbd;
  border: none;
  height: 44px;
}

.register-stepper.MuiStepper-horizontal .MuiStepConnector-root {
  width: auto;
  height: 2px;
  margin-left: 0;
  background-color: rgba(50, 81, 53, 0.3);
}

.register-stepper.MuiStepper-horizontal.all-steps-completed .MuiStepConnector-root {
  animation-name: stepper-completed-connector-horizontal;
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  animation-iteration-count: 1;
}

.register-stepper .MuiStepConnector-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  min-height: auto;
  border: none;
  background-color: #ffffff;
  transition: all 0.3s ease;
  transition-delay: 1.4s;
}

.register-stepper.MuiStepper-horizontal .MuiStepConnector-line {
  right: auto;
  bottom: 0;
  width: 0;
  height: 100%;
  background-color: #325135;
}

.register-stepper .MuiStepConnector-root.Mui-completed .MuiStepConnector-line,
.register-stepper .MuiStepConnector-root.Mui-active .MuiStepConnector-line {
  width: 100%;
  height: 100%;
  transition-delay: 0.5s;
}

.register_form {
  margin-top: 50px;
  text-align: left;
}

.register_form>.MuiGrid2-root {
  margin-bottom: 20px;
}

.register_form>.MuiGrid2-root:not(:last-child)>.MuiFormControl-root {
  margin-bottom: 8px;
}

.register_form .MuiInputLabel-root,
.register_form .MuiInputLabel-root.MuiInputLabel-shrink,
.register_form .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--black-color-hexa-50);
}

.register_form .MuiInputLabel-root.MuiInputLabel-shrink,
.register_form .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused {
  -webkit-transform: translate(0, -6px) scale(1);
  -moz-transform: translate(0, -6px) scale(1);
  -ms-transform: translate(0, -6px) scale(1);
  transform: translate(0, -6px) scale(1);
}

.register_form .MuiInput-input,
.register_form .MuiFormLabel-root,
.register_form .MuiFormLabel-root.Mui-focused,
.register_form .MuiInputLabel-root,
.register_form .MuiInputLabel-root.Mui-focused {
  font-weight: 500;
  font-size: 17px;
  color: rgba(6, 10, 6, 0.8);
}

.MuiList-root .MuiMenuItem-root.Mui-selected {
  background-color: rgba(58, 96, 63, 0.08);
}

.MuiList-root .MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(58, 96, 63, 0.12);
}

.register_form .MuiFormLabel-root a {
  font-weight: 700;
  color: var(--themedark-color-hexa);
  text-decoration: none;
}

.register_form .MuiFormLabel-root a:hover,
.register_form .MuiFormLabel-root a:focus,
.register_form .MuiFormLabel-root a:active {
  text-decoration: underline;
}

.register_form .MuiInput-input:-webkit-autofill,
.register_form .MuiInput-input:autofill {
  /* box-shadow: 0 0 0 30px rgba(47, 78, 50, 0.1) inset;
  -webkit-box-shadow: 0 0 0 30px rgba(47, 78, 50, 0.1) inset;
  -webkit-text-fill-color: #3a603f;
  color: #3a603f; */
  padding-left: 10px;
}

.register_form .MuiInput-input:focus {
  background-color: transparent;
}

.register_form .MuiFormLabel-root.lable_color_theme,
.register_form .MuiFormControl-root.lable_color_theme .MuiFormLabel-root,
.register_form .MuiFormControlLabel-root.lable_color_theme {
  color: var(--theme-color-hexa);
}

.register_form .MuiInput-underline:before,
.register_form .MuiInput-underline:after {
  border-color: var(--themedark-color-hexa);
}

.register_form .MuiFormHelperText-root {
  font-weight: 400;
  font-size: 12px;
  color: rgba(6, 10, 6, 0.6);
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.register_form .MuiFormHelperText-root.helper_color_theme,
.register_form .MuiFormControl-root.helper_color_theme .MuiFormHelperText-root {
  color: var(--theme-color-hexa);
}

.register_form .MuiCheckbox-root .MuiTypography-root {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
}

.register_form .MuiCheckbox-root.Mui-disabled {
  opacity: 0.5;
}

.register_form .MuiCheckbox-root.Mui-checked,
.register_form .MuiRadio-root.Mui-checked {
  color: var(--themedark-color-hexa);
}

.register_form .phone_input_group .autocomplete .MuiAutocomplete-clearIndicator {
  display: none;
}

.register_form .phone_input_group .autocomplete .MuiFormLabel-asterisk {
  display: none;
}

.register_form .phone_input_group .autocomplete .MuiAutocomplete-input {
  width: auto;
  min-width: auto;
  flex: 1 0 0;
}

.MuiAutocomplete-popper[role="presentation"] {
  min-width: 300px !important;
  margin-left: 90px !important;
}

.register_form .phone_input_group .autocomplete .MuiInputBase-root {
  margin: 0;
}

.phone_input_group.MuiGrid2-container {
  margin: 0 -8px 30px;
}

.phone_input_group>.MuiGrid2-root {
  padding: 0 8px;
}

.register_form .register-select .MuiSelect-select {
  padding-left: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--themedark-color-hexa);
  border-radius: 0;
}

.register_form .register-select .MuiOutlinedInput-notchedOutline {
  padding: 0;
}

.register_form .MuiFormGroup-root {
  margin-top: 10px;
}

.register_form .MuiFormGroup-root .MuiGrid2-container {
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.register_form .MuiFormGroup-root .MuiFormControlLabel-root {
  margin: 0;
}

.register_form .MuiFormGroup-root .MuiFormControlLabel-root:not(:last-child) {
  margin-right: 30px;
}

.register_form .MuiFormGroup-root .MuiButtonBase-root.MuiRadio-root {
  padding: 0;
  margin-right: 5px;
}

.register_form .MuiOutlinedInput-notchedOutline {
  border: none;
}

.warning_info_box+.register_form {
  margin-top: 25px;
}

.warning_info_box {
  background: var(--white-color-hexa);
  border-radius: 10px;
  padding: 15px 20px;
  text-align: left;
  margin-top: 17px;
}

.employe_profile_bx .warning_info_box {
  background-color: var(--white-color-hexa-50);
  margin: 0 0 10px;
}

.warning_info_box.benefit {
  background: transparent;
  border: 1px solid var(--black-color-hexa);
}

.warning_info_box>.MuiGrid2-container {
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.MuiGrid2-root.warning_box_icon {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
  margin-right: 20px;
  padding: 0;
}

.warning_box_icon .icon_bx {
  width: 50px;
  height: 50px;
  background-color: rgba(57, 96, 61, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning_info_box .MuiSvgIcon-root {
  color: var(--themedark-color-hexa);
}

.warning_box_icon {
  width: 66px;
  text-align: center;
}

.MuiGrid2-root.warning_box_cont {
  flex: 1 0 0;
  max-width: 100%;
  width: auto;
}

.warning_box_cont {
  width: calc(100% - 66px);
}

.warning_info_box h3 {
  font-weight: 800;
  font-size: 15px;
  line-height: 1.4;
  color: var(--themedark-color-hexa);
  margin-bottom: 5px;
}

.warning_info_box p {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa);
  margin: 0;
}

.warning_info_box p a {
  color: var(--themedark-color-hexa);
  text-decoration: none;
}

.warning_info_box p a:hover,
.warning_info_box p a:focus {
  text-decoration: underline;
}

.date_picker_employee_wrap.MuiGrid2-container {
  margin-left: -10px;
  margin-right: -10px;
}

.date_picker_employee_wrap.MuiGrid2-container .MuiGrid2-root.date_picker_employee {
  padding-left: 10px;
  padding-right: 10px;
}

.date_picker_employee>.MuiGrid2-root {
  padding-top: 0;
  padding-bottom: 0;
}

.date_picker_employee .MuiFormControl-root {
  width: 100%;
  border-bottom: 1px solid rgba(6, 10, 6, 0.7);
}

.date_picker_employee .MuiInputBase-root.Mui-focused .MuiIconButton-root,
.date_picker_employee .MuiInputBase-root .MuiIconButton-root:hover,
.date_picker_employee .MuiInputBase-root .MuiIconButton-root:focus {
  color: var(--theme-color-hexa);
}

.date_picker_employee .MuiInputBase-root {
  padding: 0;
  flex-direction: row-reverse;
}

.date_picker_employee .MuiInputAdornment-root {
  padding: 0;
  margin: 0;
}

.date_picker_employee .MuiInputAdornment-root .MuiButtonBase-root {
  padding: 0;
  margin-right: 11px;
}

.date_picker_employee .MuiInputBase-input {
  padding: 5px 0;
}

.date_picker_employee .MuiInputBase-input::placeholder {
  text-transform: uppercase;
}

.accual_gross_salary .MuiInputBase-root {
  width: 100%;
}

.accual_gross_salary .MuiInputAdornment-root .MuiTypography-root,
.register_form .MuiInputAdornment-root .MuiTypography-root {
  margin: 0;
}

.register_form .MuiInputAdornment-root+.MuiInputBase-input {
  padding-left: 15px;
}

.register_form .MuiInputBase-multiline {
  background: var(--white-color-hexa-50);
  border: 1px solid var(--black-color-hexa);
  border-radius: 10px;
}

.register_form .file_upload .theme_bttn {
  border-radius: 10px;
  padding: 20px;
  border-style: dashed;
}

.register_form .file_upload .theme_bttn.error {
  border-color: var(--red-color-hexa);
  background-color: var(--red-color-hexa);
  color: var(--white-color-hexa);
}

.register_form .file_upload .theme_bttn:focus {
  color: var(--theme-color-hexa);
  background-color: var(--white-color-hexa);
}

.register_form .file_upload .theme_bttn.error:focus {
  color: var(--red-color-hexa);
  background-color: var(--white-color-hexa);
}

.register-sidebar {
  position: static;
}

.register-sidebar h3 {
  font-weight: 500;
  font-size: 25px;
  color: var(--white-color-hexa-50);
}

.register-sidebar h4 {
  font-weight: 800;
  font-size: 15px;
  line-height: 1.4;
  color: var(--white-color-hexa-50);
}

.register-sidebar p {
  font-weight: 400;
  font-size: 12px;
  color: var(--white-color-hexa-50);
}

.auth_top_header.MuiGrid-container,
.auth_top_header.MuiGrid2-container {
  padding: 50px 35px 30px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.auth_top_header.MuiGrid2-container .MuiIconButton-root {
  padding: 0;
  margin-right: 10px;
}

.auth_top_header.MuiGrid-container.justify_start,
.auth_top_header.MuiGrid2-container.justify_start {
  justify-content: flex-start;
}

.auth_top_header.MuiGrid-container>.MuiGrid-root,
.auth_top_header.MuiGrid2-container>.MuiGrid2-root {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  color: var(--theme-color-hexa-50);
}

.auth_top_header_progress {
  color: var(--theme-color-hexa-50);
}

.auth_top_header_progress .MuiLinearProgress-root {
  background: #d9d9d9;
  border-radius: 10px;
}

.auth_top_header_progress .MuiLinearProgress-bar {
  background-color: var(--theme-color-hexa-50);
}

.auth_top_header_progress .MuiTypography-root {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: var(--theme-color-hexa-50);
}

.auth_top_header_progress p {
  margin: 0;
}

.register_form_card_group {
  display: block;
}

.register_form_card {
  background: var(--white-color-hexa);
  box-shadow: 0px 0px 4px rgba(var(--black-color-rgb), 0.25);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 30px 20px 20px;
  text-align: left;
}

.register_form_card_grid.MuiGrid2-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.register_form_card .MuiTypography-h4 {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4;
  color: var(--black-color-hexa);
  margin-bottom: 5px;
}

.register_form_card .MuiTypography-body2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--black-color-hexa);
  margin: 0;
}

.register_form_card_formcontrol.MuiFormControlLabel-root {
  margin-left: 0;
  width: 100%;
  font-weight: 500;
  font-size: 17px;
  color: var(--black-color-hexa);
  text-align: left;
}

.register_form_card_formcontrol .MuiTypography-root {
  font-weight: 500;
  font-size: 17px;
  color: var(--black-color-hexa);
  flex: 1 0 0;
}

.register_form_card_formcontrol .MuiButtonBase-root {
  margin-left: 25px;
}

/* SIDEBAR */
.sidebar_layout {
  height: 100vh;
  min-height: 100%;
}

.sidebar_layout {
  margin: 0;
  padding: 0;
}

.sidebar_layout.MuiGrid2-root {
  margin: 0;
  height: 100vh;
}

.sidebar_layout .sidebar_layout_drawer {
  max-width: 273px;
  height: 100%;
  padding: 0;
  position: relative;
  transition: all 0.3s ease;
}

.sidebar_layout_drawer *::selection {
  background-color: var(--white-color-hexa);
  color: var(--themedark-color-hexa);
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle {
  max-width: 65px;
}

.sidebar_layout .sidebar_layout_drawer>.MuiBox-root {
  height: 100%;
}

.sidebar_layout .MuiDrawer-docked {
  width: 100%;
  height: 100%;
  position: relative;
}

.sidebar_layout .MuiDrawer-docked .MuiDrawer-paper {
  width: 100%;
  max-width: 100%;
  position: absolute;
  border: none;
  background-image: linear-gradient(180deg,
      var(--themedark-color-hexa) 0%,
      var(--themelight-color-hexa) 100%);
}

.sidebar_layout .drawer_header {
  padding: 20px 23px 20px 30px;
  width: 100%;
  height: auto;
  min-height: 76px;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .MuiDrawer-docked .drawer_header {
  padding: 18px 5px;
  text-align: center;
}

.sidebar_layout .drawer_header .MuiGrid2-container {
  width: 100%;
  padding: 0;
  justify-content: space-between;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_header .MuiGrid2-container {
  justify-content: center;
}

.sidebar_layout .drawer_header .MuiGrid2-root {
  margin-left: 0;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_header_logo {
  display: none;
}

.sidebar_layout .drawer_header .MuiGrid2-root.grid_item_avatar {
  flex: 1 0 0;
  padding: 0 20px;
  text-align: right;
}

.sidebar_layout .drawer_header .MuiAvatar-root {
  cursor: pointer;
  background-color: var(--white-color-hexa);
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: #365138;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sidebar_layout .drawer_header .MuiIconButton-root {
  margin: 0;
  padding: 0;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .MuiDrawer-docked .drawer_header .icon_bttn {
  margin: 0;
}

.sidebar_layout .drawer_header .MuiIconButton-root .icon_hamburger_sidebar {
  display: block;
}

.sidebar_layout .drawer_header .MuiIconButton-root .icon_hamburger_menu {
  display: none;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_header .MuiIconButton-root .icon_hamburger_sidebar {
  display: none;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_header .MuiIconButton-root .icon_hamburger_menu {
  display: block;
}

.sidebar_layout .drawer_body {
  flex: 1 0 0;
  padding: 20px 0;
  z-index: 1;
}

.MuiButton-root.drawer_body_close {
  display: none;
}

.MuiIconButton-root.single_drop_close_bttn {
  position: absolute;
  top: 15px;
  right: 8px;
  color: #ffffff;
  width: 50px;
  height: 50px;
}

.MuiIconButton-root.single_drop_close_bttn svg {
  width: 100%;
  height: 100%;
}

.single_drop_img {
  width: calc(100% - 30px);
  max-width: 800px;
  height: auto;
  object-fit: contain;
}

.sidebar_layout .drawer_body p.list_item_heading {
  margin-top: 10px;
  padding: 15px 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.1;
  color: var(--white-color-hexa);
  text-transform: uppercase;
  line-height: 1;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body p.list_item_heading {
  display: none;
}

.sidebar_layout .drawer_body .MuiList-root {
  padding: 0;
}

.sidebar_layout .drawer_body .MuiList-root .MuiList-root {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0;
}

.sidebar_layout .drawer_body .MuiListItem-root {
  margin-bottom: 10px;
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root {
  padding: 10px 30px;
  min-height: auto;
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiListItemIcon-root {
  width: 20px;
  min-width: auto;
  color: var(--white-color-hexa);
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiListItemIcon-root .material-icons {
  height: 100%;
  min-height: 100%;
  margin: auto;
  font-size: 20px;
  line-height: 1;
  display: flex;
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiListItemIcon-root svg {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiListItemText-root {
  margin-left: 20px;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body .MuiListItem-root .MuiListItemText-root {
  display: none;
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:hover,
.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:focus,
.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:active,
.sidebar_layout .drawer_body .MuiListItem-root.active .MuiButtonBase-root {
  background-color: var(--white-color-hexa);
  color: var(--theme-color-hexa-50);
}

.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root{
  background-color: var(--red-color-hexa);
}
.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:hover,
.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:focus,
.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:active {
  /* background-color: rgba(255, 0, 0, 0.4); */
  background-color: rgb(254 153 160);
  color: var(--red-color-hexa);
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_body .MuiListItem-root .MuiButtonBase-root {
  padding: 10px;
  min-height: 100%;
  justify-content: center;
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:hover .MuiListItemIcon-root svg path,
.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:focus .MuiListItemIcon-root svg path,
.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:active .MuiListItemIcon-root svg path,
.sidebar_layout .drawer_body .MuiListItem-root.active .MuiButtonBase-root .MuiListItemIcon-root svg path {
  fill: var(--theme-color-hexa-50);
}

.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:hover .MuiListItemIcon-root svg path,
.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:focus .MuiListItemIcon-root svg path,
.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:active .MuiListItemIcon-root svg path {
  fill: var(--red-color-hexa);
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiTypography-root {
  line-height: 1;
  font-weight: 400;
  font-size: 15px;
  color: var(--white-color-hexa);
}

.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:hover .MuiTypography-root,
.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:focus .MuiTypography-root,
.sidebar_layout .drawer_body .MuiListItem-root .MuiButtonBase-root:active .MuiTypography-root,
.sidebar_layout .drawer_body .MuiListItem-root.active .MuiButtonBase-root .MuiTypography-root {
  color: var(--theme-color-hexa-50);
}

.sidebar_layout .drawer_body .MuiListItem-root.logout_list_item .MuiButtonBase-root:hover .MuiTypography-root {
  color: var(--red-color-hexa);
}

.sidebar_layout .drawer_footer {
  background: rgba(39, 63, 41, 1);
  padding: 15px 24px;
}

.sidebar_layout .drawer_footer .MuiGrid2-container {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .MuiDrawer-docked .drawer_footer .MuiGrid2-container {
  justify-content: center;
}

.sidebar_layout .drawer_footer .MuiGrid2-container .MuiGrid2-root,
.sidebar_layout .drawer_footer .MuiGrid2-container .MuiGrid2-root .MuiIconButton-root {
  padding: 0;
}

.sidebar_layout .drawer_footer .grid_item_avatar {
  display: flex;
  align-items: center;
}

.sidebar_layout .drawer_footer .MuiAvatar-root {
  width: 40px;
  height: 40px;
  background: var(--white-color-hexa);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: #365138;
  text-transform: uppercase;
  margin-right: 20px;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_footer .MuiAvatar-root {
  margin-right: 0;
}

.sidebar_layout .drawer_footer .MuiGrid2-container .avatar_typography_group {
  flex: 1 0 0;
  max-width: 100px;
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .drawer_footer .MuiGrid2-container .avatar_typography_group {
  display: none;
}

.sidebar_layout .drawer_footer .avatar_typography_group h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: var(--white-color-hexa);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar_layout .drawer_footer .avatar_typography_group p {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.1;
  color: rgba(255, 255, 255, 0.7);
}

.sidebar_layout .sidebar_layout_drawer.sidebar_menu_toggle .MuiDrawer-docked .drawer_footer {
  padding: 12px;
}

.sidebar_checkbox_list.MuiList-root {
  padding: 22px 0;
}

.sidebar_checkbox_list.MuiList-root .MuiListItem-root:not(:last-child) {
  margin-bottom: 25px;
}

.sidebar_checkbox_list.MuiList-root .MuiListItemButton-root {
  padding: 0;
  flex-wrap: nowrap;
}

.sidebar_checkbox_list.MuiList-root .MuiListItemButton-root:hover {
  background-color: transparent;
}

.sidebar_checkbox_list.MuiList-root .MuiListItemIcon-root {
  width: 30px;
  min-width: auto;
}

.sidebar_checkbox_list.MuiList-root .MuiListItemText-root {
  width: calc(100% - 30px);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--white-color-hexa-50);
}

.sidebar_checkbox_list.MuiList-root .MuiCheckbox-root {
  padding: 0;
  margin: 0;
  width: 15px;
  height: 15px;
  color: var(--white-color-hexa);
}

/* DASHBOARD */
.MuiButton-root.help_bttn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 3;
}

.sidebar_layout .sidebar_layout_page {
  padding: 50px;
  flex: 1 0 0;
  height: 100%;
  overflow: auto;
}

.dash_container {
  width: 100%;
  max-width: 570px;
  margin: auto;
  padding: 0 10px;
}

.page_heading {
  align-items: center;
}

.page_heading.MuiTypography-root {
  font-weight: 500;
  font-size: 27px;
  line-height: 1.5;
  color: var(--themedark-color-hexa);
  margin-bottom: 10px;
}

.page_head_para.MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: #252525;
}

.page_head_para.MuiTypography-root a {
  color: var(--themedark-color-hexa);
  font-weight: 800;
  text-decoration: none;
}

.page_head_para.MuiTypography-root a[disabled] {
  opacity: 0.5;
  text-decoration: none;
}

.page_head_para.MuiTypography-root a[disabled]:hover,
.page_head_para.MuiTypography-root a[disabled]:focus {
  cursor: auto;
  text-decoration: none;
}

.page_head_para.MuiTypography-root a:hover,
.page_head_para.MuiTypography-root a:focus {
  text-decoration: underline;
}

.page_head_bttn.MuiButtonBase-root {
  align-items: center;
  background-color: var(--theme-color-hexa);
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: var(--white-color-hexa);
  padding: 10px 20px;
  text-transform: initial;
}

.page_head_bttn.MuiButtonBase-root:hover,
.page_head_bttn.MuiButtonBase-root:focus {
  background-color: var(--themedark-color-hexa);
}

.page_head_bttn.MuiButtonBase-root .MuiButton-startIcon {
  width: 20px;
  margin-right: 5px;
}

.page_head_bttn.MuiButtonBase-root .MuiButton-startIcon img,
.page_head_bttn.MuiButtonBase-root .MuiButton-startIcon svg {
  max-width: 100%;
}

.dash_tabs .MuiTabs-root {
  min-height: auto;
}

.dash_tabs .MuiTabs-flexContainer {
  border-bottom: 2px solid #e1e4e2;
}

.dash_tabs .MuiTab-root.MuiButtonBase-root {
  padding: 10px 0 5px;
  min-width: auto;
  min-height: auto;
  overflow: visible;
}

.dash_tabs .MuiTab-root.MuiButtonBase-root.Mui-selected {
  color: var(--theme-color-hexa);
}

.dash_tabs .MuiTab-root.MuiButtonBase-root:not(:last-child) {
  margin-right: 45px;
}

.dash_tabs .MuiTab-root .MuiTypography-root {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
  text-transform: capitalize;
}

.dash_tabs .MuiTab-root .MuiBadge-badge {
  min-width: auto;
  width: 12px;
  height: 12px;
  background-color: var(--theme-color-hexa);
  font-weight: 800;
  font-size: 8px;
  line-height: 1;
  color: var(--white-color-hexa);
  padding: 0;
}

.dash_tabs .MuiTab-root.Mui-selected .MuiTypography-root {
  font-weight: 800;
}

.dash_tabs .MuiTabs-indicator {
  background-color: var(--theme-color-hexa);
}

.tab-pannel {
  padding: 30px 0;
}

.tab-pannel>.MuiBox-root {
  padding: 0;
}

.need_attention_bx {
  position: relative;
  background: var(--white-color-hexa);
  border-radius: 10px;
  padding: 16px 20px;
}

.need_attention_bx.updated_bx {
  padding: 20px 28px;
}

.need_attention_bx:not(:last-child) {
  margin-bottom: 15px;
}

.need_attention_bx .MuiGrid2-container {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.need_attention_grid_cont {
  flex: 1 0 calc(100% - 136px);
  max-width: calc(100% - 136px);
}

.need_attention_grid_img {
  width: 30%;
  max-width: 136px;
}

.need_attention_bx h4 {
  font-weight: 800;
  font-size: 17px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
  margin-bottom: 10px;
}

.need_attention_bx p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
  margin-bottom: 10px;
}

.need_attention_bx a {
  padding: 0;
  font-weight: 800;
  font-size: 15px;
  line-height: 1.4;
  color: var(--theme-color-hexa);
  text-transform: initial;
  display: inline-block;
  text-decoration: none;
}

.need_attention_bx a:hover,
.need_attention_bx a:focus {
  text-decoration: underline;
}

.no_updates_panel {
  width: 100%;
  text-align: center;
}

.no_updates_panel img {
  margin-bottom: 38px;
}

.no_updates_panel h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
  margin-bottom: 10px;
}

.no_updates_panel p {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.1;
  color: rgba(6, 10, 6, 0.7);
}

.updated_bx_close_bttn.MuiIconButton-root {
  position: absolute;
  top: 4px;
  right: 4px;
}

/* TEAM */
.team_top_heading {
  align-items: center;
  justify-content: space-between;
}

.team_top_heading.MuiGrid2-container {
  margin-bottom: 30px;
  padding: 0;
}

.team_top_heading .page_heading.MuiTypography-root {
  margin: 0;
}

.data_table {
  background: var(--white-color-hexa);
  border-radius: 10px;
  /* overflow: hidden; */
  /* box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15); */
}

.MuiGrid2-container.data_table_filters_grid {
  position: relative;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 12px;
  margin: 0;
  border-radius: 10px 10px 0 0;
}

.data_table_filters_grid>.MuiFormControl-root {
  margin-right: 10px;
}

.data_table_filters_grid .outer_searchfilled .MuiInputBase-input {
  padding: 2px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
  max-width: 85px;
  flex: 1 0 0;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
}

.data_table_filters_grid .outer_searchfilled .MuiInputBase-input:focus {
  border-color: #ccc;
}

.data_table_filters_grid .outer_searchfilled .MuiInputBase-input:not(:placeholder-shown) {
  border-color: var(--black-color-hexa-50);
}

.data_table_filters_grid .outer_searchfilled .MuiInputAdornment-root .MuiSvgIcon-root {
  fill: var(--black-color-hexa);
}

.data_table_filters_grid .outer_searchfilled .MuiOutlinedInput-notchedOutline {
  border: none;
  outline: 0;
}

.data_table_filters_grid .filter_group {
  align-items: center;
  margin-right: 20px;
}

.data_table_filters_grid .filter_group .MuiTypography-root {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
}

.table_edit_controller {
  position: relative;
}

.edit_filter_menu {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 3;
  min-width: 320px;
}

.edit_filter_menu.is_small {
  min-width: 230px;
}

.MuiButton-root.edit_filter_menu_close {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.edit_filter_menu_bx {
  z-index: 2;
  padding: 15px;
  background-color: var(--white-color-hexa);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 5px -3px rgba(var(--black-color-rgb), 0.2),
    0px 8px 10px 1px rgba(var(--black-color-rgb), 0.14),
    0px 3px 14px 2px rgba(var(--black-color-rgb), 0.12);
  width: 100%;
  max-width: 100%;
}

.edit_filter_menu.is_small .edit_filter_menu_bx {
  padding: 8px;
}

.edit_filter_menu_bx .MuiFormControl-root {
  width: 100%;
  background: var(--white-color-hexa);
  margin: 0;
  padding: 8px;
}

.edit_filter_menu_bx .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-color-hexa);
}

.edit_filter_menu_bx .MuiList-root {
  max-height: 300px;
  overflow: auto;
}

.edit_filter_menu.is_small .edit_filter_menu_bx .MuiListItem-root {
  padding: 5px;
}

.edit_filter_menu_bx .MuiListItem-root .MuiListItemText-root {
  margin-left: 10px;
}

.edit_filter_menu_bx .MuiListItem-root .MuiSwitch-root .MuiSwitch-track {
  background-color: var(--theme-color-hexa);
}

.edit_filter_menu_bx .MuiListItem-root .MuiSwitch-root.inactive .MuiSwitch-switchBase {
  color: #777777;
}

.edit_filter_menu_bx .MuiListItem-root .MuiSwitch-root .MuiSwitch-switchBase {
  color: var(--theme-color-hexa);
}

.edit_filter_menu_bx .MuiListItem-root .MuiSwitch-root.inactive .MuiSwitch-switchBase {
  color: #777777;
}

.edit_filter_menu_bx .MuiList-root .MuiListItemText-root .MuiTypography-root {
  font-size: 14px;
  text-transform: uppercase;
}

.data_table .MuiTableCell-root {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
  padding-left: 12px;
  padding-right: 12px;
}

.data_table th.MuiTableCell-root {
  font-weight: 800;
}

.data_table .MuiTableHead-root {
  background: #d9d9d9;
}

.data_table .MuiTableHead-root .MuiTableCell-root {
  background: #d9d9d9;
  text-transform: uppercase;
  white-space: nowrap;
}

.data_table .MuiTableHead-root .MuiTableCell-root.alert_status_cell {
  min-width: 138px;
}

.data_table.no_data_found .MuiTableHead-root .MuiTableCell-root.alert_status_cell {
  min-width: auto;
}

.data_table .table_container {
  width: 100%;
  overflow-x: auto;
}

.data_table .MuiTable-root {
  min-width: 710px;
}

.data_table .MuiTable-root .MuiTableBody-root .MuiTableCell-root {
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data_table .MuiTable-root .MuiTableBody-root .MuiTableRow-root:not(:last-child) .MuiTableCell-root {
  border-color: rgba(var(--black-color-rgb), 0.2);
}

.data_table .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}

.data_table .MuiTable-root .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root {
  background-color: var(--white-color-hexa-50);
  cursor: pointer;
}

.data_table .MuiTable-root .MuiTableBody-root .MuiTableCell-root .MuiButtonGroup-root {
  width: 100%;
  min-width: 80px;
}

.data_table.no_data_found .table_container {
  overflow: hidden;
}

.data_table.no_data_found .MuiTable-root {
  min-width: 100%;
}

.data_table.no_data_found .MuiTable-root .MuiTableHead-root {
  display: block;
  width: 100%;
  overflow: hidden;
}

.data_table.no_data_found .MuiTable-root .MuiTableHead-root .MuiTableRow-root {
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  min-width: 100%;
}

.data_table.no_data_found .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
  flex: auto;
}

.data_table.no_data_found .MuiTable-root,
.data_table.no_data_found .MuiTable-root .MuiTableBody-root,
.data_table.no_data_found .MuiTable-root .MuiTableBody-root .MuiTableRow-root,
.data_table.no_data_found .MuiTable-root .MuiTableBody-root .MuiTableCell-root {
  display: block;
  width: 100%;
}

.data_table.no_data_found .MuiTable-root .MuiTableBody-root .MuiTableCell-root {
  max-width: 100%;
}

.data_table .MuiTable-root .action_bttn.MuiIconButton-root {
  padding: 0;
  min-width: 24px;
}

.data_table .MuiTable-root .avatar_container {
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

.data_table .MuiTable-root .avatar_container .MuiAvatar-root {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-color: #2c432e;
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
}

.data_table .MuiTable-root .avatar_container span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data_table .MuiTable-root .progress_container {
  align-items: center;
  padding: 0;
  margin: 0;
}

.data_table .MuiTable-root .progress_container .MuiLinearProgress-root {
  flex: 1 0 0;
  background: #d9d9d9;
  border-radius: 10px;
  height: 5px;
}

.data_table .MuiTable-root .progress_container .MuiLinearProgress-bar {
  background-color: var(--theme-color-hexa-50);
}

.data_table .MuiTable-root .progress_container .MuiTypography-root {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--theme-color-hexa-50);
  width: 30px;
  text-align: right;
}

.data_table .MuiTable-root .MuiAlert-root {
  padding: 5px 10px;
  display: inline-flex;
  border-radius: 0;
}

.data_table .MuiTable-root .MuiAlert-root.MuiAlert-standardSuccess {
  background-color: rgba(57, 96, 61, 0.4);
}

.data_table .MuiTable-root .MuiAlert-root.MuiAlert-standardWarning {
  background-color: #ffd8a5;
}

.data_table .MuiTable-root .MuiAlert-root.MuiAlert-standardError {
  background-color: rgba(255, 0, 0, 0.4);
}

.data_table .MuiTable-root .MuiAlert-root.MuiAlert-standardInfo {
  background-color: rgb(185 235 255);
}

.data_table .MuiTable-root .MuiAlert-root .MuiAlert-message {
  padding: 0;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: -2px;
  display: flex;
  align-items: center;
}

.data_table .MuiTable-root .MuiAlert-root .MuiAlert-message .MuiSvgIcon-root {
  margin-left: 5px;
  width: 18px;
}

.data_table .MuiTable-root .MuiAlert-root.MuiAlert-standardSuccess .MuiAlert-message {
  color: var(--theme-color-hexa);
}

.data_table .MuiTable-root .MuiAlert-root.MuiAlert-standardError .MuiAlert-message {
  color: var(--red-color-hexa);
}

.data_table .MuiTablePagination-root {
  background: #d9d9d9;
  text-transform: uppercase;
  border-radius: 0 0 10px 10px;
}

.data_table .MuiTablePagination-root .MuiToolbar-root {
  padding: 0 15px;
}

.data_table .MuiTablePagination-root .MuiInputBase-root {
  margin-right: 15px;
}

.data_table .MuiTablePagination-root .MuiTablePagination-selectLabel,
.data_table .MuiTablePagination-root .MuiTablePagination-select {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
}

.data_table .MuiTablePagination-root .MuiTablePagination-select {
  min-height: auto;
}

.data_table .MuiTablePagination-root .MuiSelect-icon {
  width: 20px;
  height: 20px;
  top: 1px;
}

.data_table .MuiTablePagination-root .MuiTablePagination-displayedRows {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
}

.data_table .MuiTablePagination-root .MuiTablePagination-actions {
  margin-left: 15px;
}

.data_table .MuiTablePagination-root .MuiTablePagination-actions .MuiIconButton-root {
  padding: 0;
}

.team_not_found {
  width: 100%;
  height: 80%;
  max-width: 300px;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team_not_found>img {
  margin-bottom: 50px;
}

.team_not_found h4 {
  font-weight: 800;
  font-size: 15px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
  margin-bottom: 20px;
}

.team_not_found p {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: rgba(6, 10, 6, 0.7);
  margin-bottom: 30px;
}

.team_not_found p a {
  color: var(--themedark-color-hexa-50);
  font-weight: 800;
  text-decoration: none;
}

.team_not_found p a:hover,
.team_not_found p a:focus {
  text-decoration: underline;
}

.data_table_inner_notfound {
  text-align: center;
}

.data_table_inner_notfound img {
  margin-bottom: 30px;
}

.data_table_inner_notfound .MuiTypography-root {
  font-weight: 800;
  font-size: 12px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
  margin-bottom: 10px;
}

.data_table_inner_notfound .MuiTypography-root.MuiTypography-body2 {
  font-weight: 400;
  color: #252525;
}

.help_bttn_fixed.MuiButtonBase-root {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: var(--white-color-hexa);
  border: 2px solid var(--theme-color-hexa);
  box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15);
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  text-transform: initial;
  color: var(--theme-color-hexa);
  align-items: center;
}

.help_bttn_fixed.MuiButtonBase-root .MuiButton-startIcon {
  margin: 0 10px 0 0;
  width: 12px;
}

/* TEAM EMPLOYE DETAILS */
.sidebar_layout_page .auth_top_header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.employe_avatar_wrapper.MuiGrid2-root {
  margin-bottom: 20px;
  padding: 0;
}

.employe_avatar_wrapper .MuiTypography-root {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.1;
  color: var(--themedark-color-hexa);
  word-break: break-all;
}

.employe_avatar.MuiAvatar-root {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--white-color-hexa);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: #365138;
  margin-right: 20px;
}

.employe_profile_container.MuiGrid2-container {
  padding: 0;
  margin: 0 -15px;
}

.employe_profile_container_item.MuiGrid2-root {
  padding: 0 15px;
  margin: 0 0 30px;
}

.all_employe_profile_bx.MuiGrid2-root {
  padding-top: 50px;
}

.employe_profile_bx {
  background: var(--white-color-hexa);
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
}

.employe_status.employe_profile_container_item .employe_profile_bx {
  padding: 15px;
}

.employe_profile_bx.with_shadow {
  box-shadow: 0px 4px 4px rgba(var(--black-color-rgb), 0.25);
}

.employe_profile_bx:not(:last-child) {
  margin-bottom: 30px;
}

.employe_profile_head.MuiGrid2-root {
  align-items: center;
  padding: 30px;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.employe_status .employe_profile_head.MuiGrid2-root {
  flex-wrap: wrap;
  justify-content: initial;
}

.employe_status.employe_profile_container_item .employe_profile_head.MuiGrid2-root {
  padding: 15px;
}

.employe_profile_head.MuiGrid2-root .material-icons {
  width: 30px;
  height: 30px;
  background-color: rgba(46, 77, 49, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 1px;
  color: #2f4631;
}

.employe_profile_head.MuiGrid2-root .MuiGrid2-container {
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}

.employe_profile_head.MuiGrid2-root .MuiGrid2-root {
  padding: 0;
  margin: 0;
}

.employe_profile_head .MuiTypography-root {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
  margin-bottom: 0;
}

.employe_status.employe_profile_container_item .employe_profile_head .MuiTypography-root {
  margin-bottom: 8px;
  word-break: break-all;
}

.employe_status.employe_profile_container_item .employe_profile_head.emp_status .status_dot {
  display: table;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00d84e;
}

.employe_status.employe_profile_container_item .employe_profile_head.emp_status .status_dot.inactive {
  background-color: var(--red-color-hexa);
}

.employe_status.employe_profile_container_item .employe_profile_head.emp_status .status_dot.onboarding {
  background-color: #0dbad1;
}

.employe_status.employe_profile_container_item .employe_profile_head.emp_status .MuiTypography-body2 {
  margin-bottom: 0;
  margin-left: 8px;
}

.employe_profile_head .MuiTypography-root a {
  color: var(--black-color-hexa-50);
}

.employe_profile_head .MuiTypography-root.MuiTypography-body2 {
  font-weight: 400;
  font-size: 15px;
}

.employe_profile_head .MuiTypography-root.MuiTypography-caption {
  font-size: 12px;
}

.employe_profile_head .MuiButton-root {
  font-size: 17px;
}

.employe_profile_body {
  padding: 0 30px 30px;
}

.employe_personal_dtl.MuiGrid2-root {
  border-bottom: 1px solid #e1e4e2;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  align-items: center;
  flex-wrap: nowrap;
}

.employe_personal_dtl.MuiGrid2-root .MuiSkeleton-root.MuiSkeleton-rounded {
  height: 20px;
}

.employe_personal_dtl .MuiTypography-root {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  color: var(--black-color-hexa-50);
  flex: 1 0 0;
  margin: 0;
}

.employe_personal_dtl .MuiTypography-root.MuiTypography-body1 {
  font-weight: 500;
  flex: 0 0 42%;
  max-width: 42%;
}

.employe_personal_dtl .MuiTypography-root.MuiTypography-body2 {
  color: rgba(6, 10, 6, 0.6);
  flex: 1 0 calc(58% - 40px);
  word-break: break-all;
}

.employe_personal_dtl .MuiTypography-root.MuiTypography-body2.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.employe_personal_dtl .MuiIconButton-root svg path {
  fill: var(--black-color-hexa);
}

.employe_personal_dtl .MuiAlert-root {
  padding: 5px 10px;
  display: inline-block;
  border-radius: 0;
}

.employe_personal_dtl .MuiAlert-root .MuiAlert-message {
  padding: 0;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: -2px;
}

.employe_personal_dtl .MuiAlert-root.MuiAlert-standardSuccess {
  background-color: rgba(57, 96, 61, 0.4);
}

.employe_personal_dtl .MuiAlert-root.MuiAlert-standardSuccess .MuiAlert-message {
  color: var(--theme-color-hexa);
}

.employe_personal_dtl .MuiAlert-root.MuiAlert-standardError {
  background-color: rgba(255, 0, 0, 0.4);
}

.employe_personal_dtl .MuiAlert-root.MuiAlert-standardError .MuiAlert-message {
  color: var(--red-color-hexa);
}

.employe_personal_dtl_para.MuiGrid2-root {
  text-align: left;
  padding-top: 20px;
}

.employe_personal_dtl_para .MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #252525;
}

.company_info_sidebar_grid {
  background: #d7ded8;
}

.company_info_sidebar {
  padding: 0 20px;
}

.company_info_sidebar .avatar_container {
  align-items: center;
  padding: 50px 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--black-color-hexa-50);
  flex-wrap: nowrap;
}

.company_info_sidebar .avatar_container .img_bx {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--white-color-hexa);
  position: relative;
  margin-right: 30px;
}

.company_info_sidebar .avatar_container .MuiAvatar-root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--white-color-hexa);
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  color: #365138;
}

.company_info_sidebar .avatar_container .MuiIconButton-root {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #365138;
  color: var(--white-color-hexa);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
}

.company_info_sidebar .avatar_container .MuiIconButton-root svg {
  width: 10px;
}

.company_info_sidebar .avatar_container .MuiTypography-root {
  font-weight: 500;
  font-size: 25px;
  line-height: 1.2;
  color: #365138;
  text-transform: capitalize;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.company_info_sidebar_dtl.MuiGrid2-root {
  padding: 30px 0;
}

.company_info_sidebar_dtl:not(:last-child) {
  border-bottom: 1px solid #a9a9a9;
}

.company_info_sidebar_dtl .MuiTypography-root:not(:last-child) {
  margin-bottom: 24px;
}

.company_info_sidebar_dtl .MuiTypography-root {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.3;
  color: var(--black-color-hexa-50);
}

.company_info_sidebar_dtl .MuiTypography-body1 {
  font-size: 17px;
}

.company_info_sidebar_dtl .MuiTypography-h6 {
  font-size: 12px;
  color: rgba(6, 10, 6, 0.8);
}

.dotted_lighting_bx_wrapper {
  padding: 15px;
}

.dotted_lighting_bx_wrapper .dotted_lighting_bx {
  display: flex;
  border: 1px dashed var(--black-color-hexa);
  border-radius: 10px;
  width: 100%;
  max-width: 310px;
  padding: 0;
  text-align: left;
  justify-content: initial;
}

.dotted_lighting_bx_wrapper .dotted_lighting_bx[disabled] {
  opacity: 0.5;
}

.dotted_lighting_bx_wrapper .dotted_lighting_bx:not(:last-child) {
  margin-bottom: 20px;
}

.dotted_lighting_bx .MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: var(--black-color-hexa);
}

.dotted_lighting_bx .img_grid_item {
  width: 56px;
}

.dotted_lighting_bx .cont_grid_item {
  flex: 1 0 0;
}

.dotted_lighting_bx .img_bx {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: rgba(var(--black-color-rgb), 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotted_lighting_bx_wrapper .dotted_lighting_bx.variant_red {
  color: rgba(209, 0, 0, 0.2);
}

.dotted_lighting_bx.variant_green .img_bx {
  background-color: rgba(57, 96, 61, 0.2);
  color: #39603d;
}

.dotted_lighting_bx.variant_red .img_bx {
  background-color: rgba(209, 0, 0, 0.2);
  color: red;
}

.dotted_lighting_bx .MuiTypography-root.MuiTypography-body1 {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
}

.dotted_lighting_bx.variant_green .MuiTypography-root.MuiTypography-body1 {
  color: var(--themedark-color-hexa);
}

.dotted_lighting_bx.variant_red .MuiTypography-root.MuiTypography-body1 {
  color: #ce0000;
}

.warning_box {
  width: 100%;
  padding: 15px;
  background: var(--white-color-hexa-50);
  border-radius: 10px;
  margin-bottom: 20px;
}

.warning_box .MuiGrid-container,
.warning_box .MuiGrid2-container {
  align-items: center;
}

.warning_box .MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
}

.company_profile_layer {
  background: var(--white-color-hexa);
  box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15);
  border-radius: 10px;
}

.company_profile_layer.MuiGrid2-container {
  padding: 0;
  overflow: hidden;
}

.company_profile_layer .employe_profile_bx:not(:last-child) {
  margin-bottom: 0;
}

/* ADD EMPLOYEE */
.add_employe_final_cont .check_img_svg {
  margin-bottom: 50px;
}

.add_employe_final_cont .MuiTypography-h3 {
  margin: 0 auto 40px;
  max-width: 382px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add_employe_final_cont .login_bttn_wrapper {
  margin-top: 50px;
}

.pricing_bx {
  background-color: var(--theme-color-hexa);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(var(--black-color-rgb), 0.25);
  text-align: center;
  width: 100%;
  max-width: 250px;
  margin: auto;
}

.pricing_bx .pricing_bx_head {
  width: 100%;
  padding: 15px 0 30px;
  background-color: var(--theme-color-hexa);
}

.pricing_bx .pricing_bx_head h5 {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: var(--white-color-hexa);
}

.pricing_bx .pricing_bx_profile {
  position: relative;
}

.pricing_bx .pricing_bx_profile_bx {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  margin: auto;
  background: #4d7050;
  border: 1px solid var(--white-color-hexa);
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color-hexa);
}

.pricing_bx .pricing_bx_cont_wrapper.MuiGrid2-root {
  background-color: var(--white-color-hexa);
  padding: 55px 20px;
  border-radius: 0 0 5px 5px;
}

.pricing_bx .pricing_bx_cont h4 {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4;
  color: var(--black-color-hexa);
}

.pricing_bx .pricing_bx_save.MuiGrid2-root {
  margin-top: 20px;
}

.pricing_bx .pricing_bx_save_bx {
  display: table;
  margin: auto;
  background-color: rgba(77, 112, 80, 0.6);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: var(--white-color-hexa);
  padding: 2px 4px 0;
}

.pricing_bx .pricing_bx_price.MuiGrid2-root {
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.pricing_bx .pricing_bx_price h4 {
  font-weight: 800;
  font-size: 30px;
  line-height: 1;
  color: var(--theme-color-hexa);
  margin: 0 2px 0 0;
}

.pricing_bx .pricing_bx_price h5 {
  font-weight: 800;
  font-size: 15px;
  line-height: 1;
  color: var(--theme-color-hexa);
  margin: 0;
}

.pricing_bx .pricing_bx_price p {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: var(--black-color-hexa);
  margin: 0;
}

.pricing_bx .pricing_bx_Divider {
  border-color: rgba(var(--black-color-rgb), 0.2);
  margin: 27px 0 20px;
}

.pricing_bx .pricing_bx_list ul {
  padding: 0 0 0 20px;
}

.pricing_bx .pricing_bx_list ul li {
  list-style-type: disc;
  display: list-item;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
  margin-bottom: 10px;
}

/* EMPLOYEE REGISTER */
.user_header {
  background: var(--white-color-hexa);
  padding: 30px 50px;
}

.user_header_container {
  align-items: center;
}

.user_header_profile_container {
  align-items: center;
  justify-content: flex-end;
}

.user_header_profile .profile_avatar {
  background: #416044;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_header_profile .profile_cont {
  margin-left: 20px;
}

.user_header_profile .MuiTypography-root {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.3;
  color: #416044;
}

.user_header_profile .MuiTypography-body2 {
  font-size: 12px;
}

/* CONTRACTOR ONBOARDING */
.contractor_onboarding_cont {
  width: 100%;
  max-width: 620px;
  margin: auto;
  padding: 100px 15px;
}

.contractor_onboarding_head {
  text-align: left;
  margin-bottom: 30px;
}

.contractor_onboarding_head h2 {
  font-weight: 500;
  font-size: 27px;
  line-height: 1.4;
  color: var(--themedark-color-hexa);
  margin-bottom: 34px;
}

.contractor_onboarding_head .MuiTypography-body2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  color: var(--black-color-hexa-50);
}

.contractor_onboarding_progress {
  justify-content: space-between;
  align-items: center;
}

.contractor_onboarding_progress .MuiTypography-body1 {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: #ff7575;
}

.contractor_onboarding_blocks.MuiGrid2-root {
  margin-top: 20px;
}

.contractor_onboarding_block {
  color: var(--black-color-hexa);
  background: var(--white-color-hexa);
  border: 1px solid var(--black-color-hexa);
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contractor_onboarding_block:hover {
  background-color: var(--black-color-hexa);
  color: var(--white-color-hexa);
}

.contractor_onboarding_block.iscompleted {
  border-color: var(--themedark-color-hexa);
  color: var(--themedark-color-hexa);
}

.contractor_onboarding_block.iscompleted:hover {
  background-color: var(--themedark-color-hexa);
  color: var(--white-color-hexa);
}

.contractor_onboarding_block.inprogress {
  border-color: #ffd600;
  color: #ffd600;
}

.contractor_onboarding_block.inprogress:hover {
  background-color: #ffd600;
  color: var(--white-color-hexa);
}

.contractor_onboarding_block .MuiGrid2-container {
  align-items: center;
  justify-content: space-between;
}

.contractor_onboarding_block .MuiGrid2-container .MuiSkeleton-root.MuiSkeleton-rounded {
  width: auto;
  max-width: 250px;
  height: 25px;
  flex: 1 0 0;
  margin-right: 15px;
}

.contractor_onboarding_block .MuiTypography-root {
  font-weight: 500;
  font-size: 17px;
  line-height: 1.4;
  color: var(--black-color-hexa);
  margin: 0;
}

.contractor_onboarding_block:hover .MuiTypography-root {
  color: var(--white-color-hexa);
}

.MuiBackdrop-root.loader_backdrop {
  background-color: rgba(255, 255, 255, 0.7);
}

.pageLoading {
  width: 100vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 152px;
  height: 128px;
}

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--theme-color-hexa);
  animation: lds-grid 1.2s linear infinite;
}

/* ROW 1 */
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.25s;
}

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.5s;
}

.lds-grid div:nth-child(4) {
  top: 8px;
  left: 80px;
  animation-delay: -0.75s;
}

/* ROW 2 */
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -1s;
}

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.25s;
}

.lds-grid div:nth-child(7) {
  top: 32px;
  left: 80px;
  animation-delay: -1.5s;
}

.lds-grid div:nth-child(8) {
  top: 32px;
  left: 104px;
  animation-delay: -1.75s;
}

/* ROW 3 */
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -0s;
}

.lds-grid div:nth-child(10) {
  top: 56px;
  left: 80px;
  animation-delay: -0.25s;
}

.lds-grid div:nth-child(11) {
  top: 56px;
  left: 104px;
  animation-delay: -0.5s;
}

.lds-grid div:nth-child(12) {
  top: 56px;
  left: 128px;
  animation-delay: -0.75s;
}

/* ROW 4 */
.lds-grid div:nth-child(13) {
  top: 80px;
  left: 32px;
  animation-delay: -1s;
}

.lds-grid div:nth-child(14) {
  top: 80px;
  left: 56px;
  animation-delay: -1.25s;
}

.lds-grid div:nth-child(15) {
  top: 80px;
  left: 80px;
  animation-delay: -1.5s;
}

.lds-grid div:nth-child(16) {
  top: 80px;
  left: 104px;
  animation-delay: -1.75s;
}

/* ROW 1 */
.lds-grid div:nth-child(17) {
  top: 104px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(18) {
  top: 104px;
  left: 32px;
  animation-delay: -0.25s;
}

.lds-grid div:nth-child(19) {
  top: 104px;
  left: 56px;
  animation-delay: -0.5s;
}

.lds-grid div:nth-child(20) {
  top: 104px;
  left: 80px;
  animation-delay: -0.75s;
}

/* Prinkal CSS */
.empregister_logout_bttn.MuiButtonBase-root {
  position: fixed;
  bottom: 30px;
  left: 30px;
  background: var(--white-color-hexa);
  border: 2px solid var(--theme-color-hexa);
  box-shadow: 0px 4px 6px rgba(var(--black-color-rgb), 0.15);
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  text-transform: initial;
  color: var(--theme-color-hexa);
  align-items: center;
}